var stickyHeader = true;
var flyoutNav = true;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

var visualAppealsLoadMore = true;
var lualBodyClasses = ["PostSystemCategory_christmas-appeal-style", "PostSystemCategory_balloons-appeal-style"];

$('document').ready(function () {
  // Check for inline script after #afterCarousel
  // (the platform puts this here if the Shared layout > Auto rotate carousel box is checked)
  if (document.querySelector('#afterCarousel + script')) {
    var carouselAutoRotate = true;
  }

  $("body.homepage .carouselImageMultiple").find('.carouselSlides').slick({
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: carouselAutoRotate,
    autoplaySpeed: defaultChangeMS,
    infinite: true,
    cssEase: 'ease',
    centerPadding: '0',
  });

  $("body:not(.homepage) .carouselImageMultiple").find('.carouselSlides').slick({
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: carouselAutoRotate,
    autoplaySpeed: defaultChangeMS,
    infinite: true,
    cssEase: 'ease',
    centerPadding: '7.5%',
  });

  $(".homeFeedBox2").find('.feedList').slick({
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    autoplay: carouselAutoRotate,
    autoplaySpeed: defaultChangeMS,
    infinite: true,
    cssEase: 'ease',
    centerPadding: '0',
  });

  // Home intro
  $('.homeIntro > *:not(img)').wrapAll('<div class="homeIntroText"></div>');

  $('.homeFeedBox2 .feedsTitle, .homeFeedBox2 .feedsTitle ~ *:not(.feedList)').wrapAll('<div class="feedsTitleWrapper"></div>');

  $('.homeFeatureDetailsWrapper p, .sidebarCard p, .homeFeedBox1 .feedItemDetailsWrapper').append('<span class="readMore">Read more</span>');

  if ($('.sidebarCard').length) {
    $('.postAside').insertAfter('.contentBlockWrapper');
    $('.sidebarCard').each(function () {
      let img = $(this).find('img');
      let src = img.attr('src');
      let newSrc = src.replace('width=600&height=254', 'width=300&height=226');
      img.attr('src', newSrc);
    });
  }
});

$(window).on('load resize scroll', function () {
  $('.homeIntro .donateBtn').css('height', ($('.homeIntro .donationAmount').first().css('height')));
  $('#menuMain').css('top', $('#pageHeader').outerHeight());

});

$('.homeFeed').each(function () {
  $(this).find('.cta-button').wrapAll('<div class="cta-button-wrapper"></div>');
  $(this).find('.cta-button-wrapper').insertAfter($(this).find('.feedList'));
});

$('.homeFeaturesWrapper').insertAfter('.homeFeedsWrapper');

$('.homeBox1 > *').wrapAll('<div class="homeBox1Inner"></div>');

$('.homeBox1 .cta-button').insertAfter('.homeBox4').wrap('<div class="featureButtonWrapper">');

$('.homeBox5 > *').wrapAll('<div class="homepageNewsletterInner">');

// newsletter snippet decoration
$('<div class="newsletterDecoration arrow"></div><div class="newsletterDecoration balloon"></div>').prependTo('.homeBox5');
$('<div class="newsletterDecoration arrow"></div>').prependTo('.footerBox1');

$('.homeFeedBox2').insertAfter('.homeBox5');

$('.footerBox6').insertAfter('.pageFooter');

$('.carouselSlideDetail').each(function () {
  $('<div class="carouselDetailDecoration"></div>').prependTo($(this));
});

setTimeout(function () {
  $('<div class="headerTextDecoration"></div>').prependTo('.headerText');
  $('blockquote').prepend('<div class="blockquoteDecoration"></div>');
  if ($('body').hasClass('listing-with-feature')) {
    $('.carouselSlideTitle img').each(function () {
      $(this).removeAttr('srcset');
      let src = $(this).attr('src');
      let newSrc = src.replace('width=1920&height=447', 'width=1506&height=655');
      $(this).attr('src', newSrc);
    });
  }
}, 1);

// Animate homepage stats
if (typeof countUpStats !== "undefined" && countUpStats) {
  if ($("body").hasClass("homepage") && !$("body").hasClass("subsite") || $("body:not(.homepage)").hasClass("PostCategory_post-count-up-stats")) {
    // Set a flag so we only run it once
    let statsAnimated = false;
    // Using a setTimeout so we're not firing this every single frame of scroll
    let statsTimer;

    $(window).on("load resize scroll", function () {
      clearTimeout(statsTimer);
      statsTimer = setTimeout(function () {
        $(".homeIntroText h1").each(function () {
          if (
            !statsAnimated &&
            $(this).length &&
            $(this).isInViewport()
          ) {
            const element = $(this);
            const originalText = element.text();
            let elementText = originalText;

            const firstChar = elementText.charAt(0);
            const lastChar = elementText.slice(-1);
            let prefix = "";
            let suffix = "";

            // Check to see if we have a countUpStatsDuration variable
            // If so, use that value for the animation swing below
            // Otherwise, let's use a default value of 3000
            const animationDuration =
              typeof countUpStatsDuration !== "undefined" &&
                countUpStatsDuration ?
                countUpStatsDuration :
                3000;

            if (isNaN(firstChar)) {
              prefix = firstChar;
              elementText = elementText.substring(1, elementText.length);
            }

            if (isNaN(lastChar)) {
              suffix = lastChar;
              elementText = elementText.slice(0, -1);
            }

            // Strip any "," values
            elementText = elementText.replace(/,/g, "");

            // Count length of any decimal places
            const mantissaLength = elementText.split(".")[1] ?
              elementText.split(".")[1].length :
              0;

            $({
              countNum: 0
            }).animate({
              countNum: elementText,
            },

              {
                duration: animationDuration,
                easing: "swing",
                step: function (now) {
                  element.text(
                    prefix + parseFloat(now).toFixed(mantissaLength) + suffix
                  );
                },
                complete: function () {
                  element.text(originalText);
                },
              }
            );
          }
        });

        // Set our flag so that we don't animate again
        statsAnimated = true;
      }, 10);
    });
  }
}

// 647629 add spotify player between the homepage newsletter and stories section
$('.homeBox7').insertAfter('.homeBox5');

if ($('body[class*="PostSystemCategory_balloons-appeal-style"]').length) {
  $("a.button.callToActionBtn.donateBtn.donate").appendTo(".headerText");
}

var visualAppealsCustomHeightValues = {
  "PostSystemCategory_balloons-appeal-style": {
    heightSm: "-500",
    heightMd: "-400",
    heightXl: "-580"
  }
}